import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { withSnackbarTop } from '../../components/SnackbarTop';

import { login } from '../../utils/auth';

import styles from './styles';

const Login = ({ openSnackbar, history }) => {
  const classes = styles();
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const isLoggedIn = sessionStorage.getItem('@user_token');

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'email') setEmail(value);
    else if (name === 'senha') setSenha(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email !== '' && senha !== '') {
      if (email.split('@')[1] === 'sanar.com') {
        try {
          /* const resposta = await api.post('/sign_in', { email, senha });
          const { jwt } = resposta.data.data;
          sessionStorage.setItem('@user_token', jwt); */
          const data = await login(email, senha);
          // console.log(user);
          // console.log(data.signInUserSession);
          const { jwtToken } = data.signInUserSession.accessToken;
          sessionStorage.setItem('@user_token', jwtToken);
          history.push('/app/cadastrar_universidade');
        } catch (e) {
          openSnackbar('E-mail ou senha incorretos!', 'error');
        }
      } else {
        openSnackbar('Preencha todos os campos corretamente', 'error');
      }
    } else {
      openSnackbar('Preencha todos os campos', 'error');
    }
  };

  return (
    isLoggedIn ? (
      <Redirect to="/cadastrar_universidade" />
    ) : (
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <div className={classes.formContainer}>
            <main className={classes.main}>
              <h2 className={classes.h2}>Login</h2>
              <form className={classes.form}>
                <Input
                  name="email"
                  type="text"
                  label="Email"
                  value={email}
                  onChange={handleChange}
                  className={classes.fieldContainer}
                  variant="outlined"
                />
                <Input
                  name="senha"
                  type="password"
                  label="Senha"
                  value={senha}
                  onChange={handleChange}
                  className={classes.fieldContainer}
                  variant="outlined"
                />
                <div className={classes.buttonContainer}>
                  <Button type="submit" className={classes.button} onClick={handleSubmit} variant="contained">
                    ENTRAR
                  </Button>
                </div>
                <div className={classes.hr} />
              </form>
            </main>
          </div>
        </div>
      </div>
    ));
};

export default withSnackbarTop(Login);
