import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    // height: '100vh',
    width: '100%',
    // minHeight: '100vh',
    // minWidth: '100vw',
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 80px)',
    maxWidth: '410px',
    boxShadow: '1px 2px 2px 2px #c1c1c1',
    borderRadius: '5px',
    border: '1px solid #c1c1c1',
    margin: '30px',
    padding: '40px',
  },
  h2: {
    width: '100%',
    // maxWidth: '470px',
    fontSize: '25px',
    color: '#009a6b',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    margin: '20px 0px 30px 0px',
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: '470px',
    paddingTop: '36px',
  },
  inputContainer: {
    display: 'flex',
    marginBottom: '25px',
    padding: '5px',
    border: '1px solid #aaa',
    borderRadius: '5px',
    justifyContent: 'space-between',
    '&:hover': {
      borderColor: '#009a6b',
    },
  },
  fileButton: {
    alignSelf: 'flex-end',
    fontSize: '10px',
  },
  fieldContainer: {
    marginBottom: '20px',
  },
  buttonContainer: {
    marginTop: '30px',
    marginBottom: '40px',
  },
  button: {
    width: '100%',
    padding: '10px',
  },
  icon: {
    color: '#009a6b',
    height: '50px !important',
    width: '50px !important',
  },
  fileInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      fontSize: '12px',
      fontFamily: 'Helvetica',
      paddingTop: '5px',
    }
  },
  hr: {
    height: '1px',
    width: '100%',
    marginTop: '26px',
    backgroundColor: '#009a6b',
  },
}));
