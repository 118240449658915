import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '96%',
    marginBottom: '20px',
    padding: '20px 2%',
    boxShadow: '1px 1px 1px 1px #c1c1c1',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Helvetica',
    fontWeight: 500,
    // color: '#81c784',
    color: '#555',
    margin: 0,
  },
  icon: {
    height: '30px',
    width: '30px',
    marginRight: '10px',
    // color: '#81c784',
    color: '#115e1a',
  }
}));

export default ({ title, icon: Icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Icon && <Icon className={classes.icon} />}
      <h2 className={classes.title}>{title}</h2>
    </div>
  );
};
