import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../pages/Login';
import Relatorio from '../pages/Relatorio';
import PrivateRoutes from './PrivateRoutes';
import NaoCadastrada from '../pages/NaoCadastrada';
import NotFound from '../pages/NotFound';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/relatorio/:universidade" component={Relatorio} />
      <Route exact path="/app/*" component={PrivateRoutes} />
      <Route exact path="/nao_cadastrada" component={NaoCadastrada} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
