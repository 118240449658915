import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ConstantLayout from '../components/ConstantLayout';
import CadastrarUniversidade from '../pages/CadastrarUniversidade';
import Universidades from '../pages/Universidades';
import NotFound from '../pages/NotFound';

const Routes = () => (
  <Switch>
    <Route path="/app/cadastrar_universidade" component={CadastrarUniversidade} />
    <Route path="/app/universidades" component={Universidades} />
    <Route path="*" component={NotFound} />
  </Switch>
);

const PrivateRoutes = () => {
  const isLoggedIn = sessionStorage.getItem('@user_token');
  return isLoggedIn ? <ConstantLayout routes={Routes} /> : <Redirect to="/" />;
};

export default PrivateRoutes;
