import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(() => ({
  root: {
    '& label': {
      color: '#555',
    },
    '& label.Mui-focused': {
      color: '#009a6b',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#aaa',
      },
      '&:hover fieldset': {
        borderColor: '#009a6b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#009a6b',
      },
    },
  },
}))(TextField);
