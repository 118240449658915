import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';

export const withSnackbarTop = WarppedComponent => (props) => {
  const [state, setState] = useState({
    open: false,
    message: '',
    type: '',
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setState({ open: false, message: '', type: '' })
  };

  const snackbar = state.open ? (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key="top,center"
      open={true}
      onClose={() => setState({ open: false, message: '', type: '' })}
      // message={state.message}
      TransitionComponent={Slide}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={state.type || 'error'}>
        {state.message}
      </Alert>
    </Snackbar>
  ) : null;

  const openSnackbar = (message, type) => {
    setState({ open: true, message, type })
  }

  return (
    <>
      <WarppedComponent openSnackbar={openSnackbar} {...props} />
      { snackbar }
    </>
  );
};
