import React, { useState } from 'react';
import { FileCopyRounded } from '@material-ui/icons';

import Button from '../../components/Button';
import { withSnackbarTop } from '../../components/SnackbarTop';
import Input from '../../components/Input';

import bytesToSize from '../../utils/bytesToSize';

import api from '../../services/api';

import styles from './styles';

  

const CadastrarPlanilha = ({ openSnackbar }) => {
  const classes = styles();

  const [state, setState] = useState({
    universidade: '',
    file: null,
    fileName: '',
    fileSize: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'universidade') setState({ ...state, universidade: value });
  };

  const onFileChange = (event) => {
    const fileUploaded = event.target.files[0];

    setState({
      universidade: state.universidade,
      file: fileUploaded,
      fileName: fileUploaded.name,
      fileSize: bytesToSize(fileUploaded.size),
    });
  };

  const handleChooseFile = (e) => {
    e.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx';
    input.onchange = onFileChange;
    input.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.universidade !== '' && state.file) {
      try {
        const data = new FormData();
        data.append('planilha', state.file);
        data.append('universidade', state.universidade);
        await api.post('/university/cadastrar_universidade', data);

        openSnackbar('Planilha cadastrada com sucesso!', 'success');

        setState({
          universidade: '',
          file: null,
          fileName: '',
          fileSize: '',
        });
      } catch (e) {
        openSnackbar('Ocorreu um erro ao tentar cadastrar a planilha', 'error');
      }
    } else {
      openSnackbar('Preencha todos os campos!', 'error');
    }
  };

  return (
    // <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.formContainer}>
          <main className={classes.main}>
              <h2 className={classes.h2}>Cadastrar Universidade</h2>
              <form className={classes.form}>
                <Input
                  name="universidade"
                  type="text"
                  label="Universidade"
                  value={state.universidade}
                  onChange={handleChange}
                  className={classes.fieldContainer}
                  variant="outlined"
                />
                <div className={classes.inputContainer}>
                  <div>
                    <FileCopyRounded className={classes.icon} />
                    <div className={classes.fileInfoContainer}>
                      <span>{state.fileName}</span>
                      <span>{state.fileSize}</span>
                    </div>
                  </div>
                  <Button type="button" className={classes.fileButton} onClick={handleChooseFile} variant="contained">
                    Escolher arquivo
                  </Button>
                </div>
                <div className={classes.buttonContainer}>
                  <Button type="submit" className={classes.button} onClick={handleSubmit} variant="contained">
                    Cadastrar
                  </Button>
                </div>
                <div className={classes.hr} />
              </form>
            </main>
        </div>
      </div>
    // </div>
  );
};

export default withSnackbarTop(CadastrarPlanilha);
