import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Header from '../Header';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default ({ routes: Routes }) => (
  <div className={useStyles().container}>
    <Header />
    <Routes />
  </div>
);