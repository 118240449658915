import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Popover, CircularProgress } from '@material-ui/core';
import { AssignmentOutlined } from '@material-ui/icons';
import xlsx from 'xlsx';

import Header from './components/Header';
import Table from './components/Table';
import Input from '../../components/Input';
import StyledButton from '../../components/Button';
import { withSnackbarTop } from '../../components/SnackbarTop';
import Logo from '../../assets/images/sanar_resmed.png';

import LoadingPage from './components/LoadingPage';

import api from '../../services/api';

import styles from './styles';

const formatDate = (date) => date.toISOString().slice(0, 10);

const formatDateString = (date) => date.toISOString().slice(0, 10).split('-').reverse().join('/');

const reloadHour = 4;
const oneHour = 3600000;

const getHora = () => {
  const horaAtual = new Date().getHours() + new Date().getMinutes() / 60;
  return horaAtual >= reloadHour ? ((24 - horaAtual) + reloadHour) : (reloadHour - horaAtual);
};

const Relatorio = ({ openSnackbar, history }) => {
  const classes = styles();
  const { universidade } = useParams();
  const [estudantes, setEstudantes] = useState([]);
  const [inicio, setInicio] = useState(new Date());
  const [termino, setTermino] = useState(new Date());
  const [filtro, setFiltro] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);

  
  const getStudents = async () => {
    try {
      const res = await api.get(`/university/${universidade}`);
      // console.log(data);
      setEstudantes(res.data.data);
    } catch (e) {
      openSnackbar('Ocorreu um erro na busca dos estudantes', 'error');
      // console.log(e);
    }
  };

  async function runOnChoosenTime() {
    setIsTableLoading(true);
    await getStudents();
    setIsTableLoading(false);
    setTimeout(() => {
      runOnChoosenTime();
    }, getHora() * oneHour);
  }

  async function verificarUniversidade() {
    try {
      const res = await api.get(`/university/status/${universidade}`);
      // console.log(res.data.data);
      if (!res.data.data.status) history.push('/nao_cadastrada');
    } catch (e) {
      // openSnackbar('Ocorreu um erro na busca dos estudantes', 'error');
      // console.log(e);
    }
  }
  
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await verificarUniversidade();
      await runOnChoosenTime();
      setIsLoading(false);
    })();
  }, []);

  const handleChange = (e) => {
    // console.log(e.target);
    if (e.target.name === 'inicio' && e.target.value) {
      setInicio(new Date(e.target.value));
    } else if (e.target.name === 'termino' && e.target.value) {
      setTermino(new Date(e.target.value));
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dateComp = (date1, date2) => formatDate(date1) > formatDate(date2);

  const errorInicio = () => inicio && dateComp(inicio, new Date());

  const errorTermino = () => {
    if (inicio && termino) return dateComp(inicio, termino) || dateComp(termino, new Date());
    if (termino) return dateComp(termino, new Date());
    return false;
  };

  const enableFilter = () => {
    if (!inicio || !termino) return true;
    return errorInicio() || errorTermino();
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    try {
      setAnchorEl(null);
      setIsTableLoading(true);
      const res = await api.get(`/university/${universidade}`, {
        params: {
          dataInicio: formatDate(inicio),
          dataTermino: formatDate(termino),
        },
      });
      setEstudantes(res.data.data);
      setFiltro(`${formatDateString(inicio)} ~ ${formatDateString(termino)}`);
      setIsTableLoading(false);
    } catch (e) {
      setAnchorEl(null);
      setInicio(new Date());
      setTermino(new Date());
      setFiltro('');
      setIsTableLoading(false);
      openSnackbar('Ocorreu um erro na busca dos estudantes', 'error');
    }
  };

  const handleRemoveFilter = async (e) => {
    e.preventDefault();
    setAnchorEl(null);
    setInicio(new Date());
    setTermino(new Date());
    setFiltro('');
    setIsTableLoading(true);
    await getStudents();
    setIsTableLoading(false);
  };

  const handleBaixar = (e) => {
    e.preventDefault();
    const data = [
      ['Nome', 'Email', 'Semestre', 'Vídeos Vistos', 'Questões Feitas', 'Tempo Total (minutos)'],
      ...estudantes.map(({ name, email, semestre, quantidade_videos, quantidade_questoes, tempo_total }) => [
        name, email, semestre, quantidade_videos, quantidade_questoes, tempo_total,
      ]),
    ];
    const wb = xlsx.utils.book_new();
    wb.Props = {
      Title: `Relatório dos estudates da ${universidade}`,
      Subject: 'Relatório',
      Author: 'Sanar',
      CreatedDate: new Date(),
    };
    wb.SheetNames.push('Relatório');
    wb.Sheets['Relatório'] = xlsx.utils.aoa_to_sheet(data);

    const buffer = new Buffer(xlsx.write(wb, { type: 'binary', bookType:'xlsx' }), 'binary');
    const blob = new Blob([new Uint8Array(buffer)]);
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.target = '_blank';
    a.download = `${universidade}.xlsx`;
    a.click();
  };

  return (
    <div className={classes.container}>
      {isLoading && <LoadingPage />}
      <Header title={`Dashboard Sanar - ${universidade}`} icon={AssignmentOutlined} />
        <main className={classes.main}>
          <div className={classes.headerContainer}>
            <div className={classes.imageContainer}>
              <img src={Logo} className={classes.image} alt="Logo" />
            </div>
            <div className={classes.filtroContainer}>
              <StyledButton onClick={handleBaixar} className={classes.filterButton}>
                Exportar Planilha
              </StyledButton>
              <StyledButton onClick={handleClick} className={classes.filterButton}>
                {filtro || 'Filtrar por período'}
              </StyledButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={classes.popoverContentContainer}>
                  <div className={classes.datesContainer}>
                    <Input
                      name="inicio"
                      label="Data de início"
                      type="date"
                      value={inicio ? formatDate(inicio) : formatDate(new Date())}
                      // defaultValue={formatDate(new Date())}
                      onChange={handleChange}
                      inputProps={{ max: formatDate(new Date()) }}
                      error={errorInicio()}
                      // className={errorInicio() ? classes.inputError : undefined}
                      variant="outlined"
                    />
                    <Input
                      name="termino"
                      label="Data de término"
                      type="date"
                      value={termino ? formatDate(termino) : formatDate(new Date())}
                      // defaultValue={formatDate(new Date())}
                      onChange={handleChange}
                      inputProps={{ min: inicio && formatDate(inicio), max: formatDate(new Date()) }}
                      error={errorTermino()}
                      // className={errorTermino() ? classes.inputError : undefined}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.buttonsContainer}>
                    <Button onClick={handleClose}>Cancelar</Button>
                    {filtro && <Button className={classes.removeButton} onClick={handleRemoveFilter}>Remover Filtro</Button>}
                    <StyledButton onClick={handleFilter} disabled={enableFilter()}>Filtrar</StyledButton>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className={classes.tableContainer}>
            {
              (isTableLoading && !isLoading) && (
                <div className={classes.loadingContainer}>
                  <CircularProgress value={100} size={100} />
                </div>
              ) 
            }
              <Table data={estudantes} className={classes.table} />
          </div>
        </main>
    </div>
  );
};

export default withSnackbarTop(Relatorio);
