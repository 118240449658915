import React from 'react';
import { WarningRounded } from '@material-ui/icons';
import styles from './styles';

export default function () {
  const classes = styles();
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.spanContainer}>
          <WarningRounded />
          <span className={classes.span}>404 Página não encontrada</span>
        </div>
      </div>
    </div>
  );
};
