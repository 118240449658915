import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(() => ({
  root: {
    backgroundColor: '#009a6b',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#006b40',
    },
  },
}))(Button);
