import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '450px',
    boxShadow: '1px 2px 2px 2px #c1c1c1',
    borderRadius: '5px',
    border: '1px solid #c1c1c1',
    padding: '5px',
    margin: '40px',
  },
  h2: {
    width: '100%',
    maxWidth: '370px',
    fontSize: '32px',
    color: '#009a6b',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    margin: '50px 0px 30px 0px',
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '370px',
    padding: '30px 0px',
  },
  inputContainer: {
    display: 'flex',
    marginBottom: '25px',
    padding: '5px',
    border: '1px solid #aaa',
    borderRadius: '5px',
    justifyContent: 'space-between',
    '&:hover': {
      borderColor: '#009a6b',
    },
  },
  fieldContainer: {
    marginBottom: '20px',
  },
  buttonContainer: {
    marginTop: '30px',
    marginBottom: '40px',
  },
  button: {
    width: '100%',
    padding: '10px',
  },
  hr: {
    height: '1px',
    width: '100%',
    marginTop: '20px',
    backgroundColor: '#009a6b',
  },
}));
