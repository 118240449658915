import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  withStyles,
} from '@material-ui/core';

const RemButton =  withStyles(() => ({
  root: {
    backgroundColor: '#d32f2f',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#9a0007',
    },
  },
}))(Button);

const CanButton =  withStyles(() => ({
  root: {
    backgroundColor: '#ccc',
    color: '#333 !important',
    '&:hover': {
      backgroundColor: '#aaa',
    },
  },
}))(Button);

export default ({ title, msg, handleClose, handleSubmit }) => (
  <Dialog
    open
    onClose={handleClose}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{msg}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <CanButton onClick={handleClose} color="primary">Cancelar</CanButton>
      <RemButton onClick={handleSubmit} color="primary">Remover</RemButton>
    </DialogActions>
  </Dialog>
);
