import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  spanContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '600px',
    boxShadow: '1px 2px 2px 2px #c1c1c1',
    borderRadius: '5px',
    border: '1px solid #c1c1c1',
    padding: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: '50px',
      width: '50px',
      color: '#fdd835',
    },
  },
  span: {
    fontSize: '35px',
    color: '#009a6b',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    margin: '20px',
  },
}));
