import Amplify, { Auth } from 'aws-amplify';

//prod
const amplifySettings = {
  userPoolId: 'us-east-1_6kFcb60f2',
  userPoolWebClientId: '2uk1a812t1tr7b9ql9ivuati7e',
};


//dev
/* const amplifySettings = {
  userPoolId: 'us-east-1_EJnJD6R02',
  userPoolWebClientId: '2uk58uan46uqeqct7lm6osnlck',
}; */

Amplify.configure({
  Auth: amplifySettings,
  Analytics: {
    disabled: true,
  },
});

export async function getCurrentUser() {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (err) {
    return null;
  }
}

export function login(email, password) {
  return Auth.signIn(email, password);
}

export function logout() {
  return Auth.signOut();
}

export async function getToken() {
  const user = await getCurrentUser();
  return user && user.signInUserSession.accessToken.jwtToken;
}

export async function getRefreshToken() {
  const user = await getCurrentUser();
  return user && user.signInUserSession.refreshToken.token;
}
