import React, { useState, useEffect } from 'react';

import { withSnackbarTop } from '../../components/SnackbarTop';
import Table from './components/Table';

import api from '../../services/api';

import styles from './styles';

const Universidades = ({ openSnackbar: open }) => {
  const classes = styles();
  const [universidades, setUniversidades] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const resposta = await api.get('/university');
        const { data } = resposta.data;
        setUniversidades(data);
      } catch (e) {
        open('Ocorreu um erro buscar as universidades', 'error');
      }
    })();
  }, []);

  return (
    // <div className={classes.container}>
      <div className={classes.contentContainer}>
        <main className={classes.main}>
          <h2 className={classes.h2}>Universidades</h2>
          <Table
            data={universidades}
            openSnackbar={open}
            className={classes.table}
            setUniversidades={setUniversidades}
          />
          <div className={classes.hr} />
        </main>
      </div>
    // </div>
  );
};

export default withSnackbarTop(Universidades);
