import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    minHeight: '100vh',
  },
  main: {
    width: '95%',
    minHeight: '550px',
    height: '84%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '1px 2px 2px 2px #c1c1c1',
    overflow: 'hidden',
    borderRadius: '5px',
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    ['@media (max-width: 600px)']: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imageContainer: {
    width: '50%',
    display: 'flex',
    ['@media (max-width: 600px)']: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  filtroContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '2%',
    ['@media (max-width: 600px)']: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  tableContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    position: 'relative',
  },
  table: {
    height: '390px',
    maxHeight: '390px',
  },
  image: {
    width: '300px',
    margin: '10px',
  },
  popoverContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    height: '220px',
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: '#eee',
  },
  datesContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    '& button': {
      margin: '0 5px',
      fontSize: '12px',
      letterSpacing: '1px',
    },
  },
  inputError: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
    },
  },
  filterButton: {
    padding: '5px 15px',
    marginLeft: '10px',
  },
  removeButton: {
    backgroundColor: '#d50000',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#9b0000',
    },
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#eeeeeeaf',
    position: 'absolute',
    zIndex: 9999,
    '& svg': {
      color: '#009a6b',
    },
  }
}));
