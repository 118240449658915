import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    zIndex: '9999',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    minWidth: '100vw',
    height: '100vh',
    minHeight: '100vh',
    backgroundColor: '#fff',
    '& svg': {
      color: '#009a6b',
    },
    '& h2': {
      fontFamily: 'Helvetica',
      marginTop: '50px',
      fontWeight: 400,
      color: '#009a6b',
    }
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress value={100} size={180} />
      <h2>Carregando Dashboard ...</h2>
    </div>
  );
};
