import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { SchoolRounded, CreateRounded, ExitToAppRounded } from '@material-ui/icons';

import Logo from '../../assets/images/sanar_resmed.png';

import { logout } from '../../utils/auth';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '96%',
    borderBottom: '1px solid #c1c1c1',
    padding: '10px 2%',
    // boxShadow: '1px 2px 2px 2px #c1c1c1',
    // marginBottom: '1px',
    alignItems: 'center',
  },
  image: {
    width: '35%',
  },
  icon: {
    height: '30px',
    width: '30px',
    marginRight: '5px',
    color: '#009a6b',
    // color: '#115e1a',
  },
  imageContainer: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  menuContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  navItem: {
    display: 'flex',
    marginRight: '15px',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#009a6b',
    fontFamily: 'Roboto Condensed, Helvetica, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    border: 'none',
    background: 'none',
    padding: 0,
    outline: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: '#115e1a',
      '& svg': {
        color: '#115e1a',
        transform: 'scale(1.2)',
      },
    },
  },
}));

export default withRouter(({ history }) => {
  const classes = useStyles();

  async function handleLogout(e) {
    try {
      e.preventDefault();
      await logout();
      sessionStorage.clear();
      history.push('/');
    } catch (e) {
      alert('Erro ao deslogar');
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={Logo} className={classes.image} alt="Logo" />
      </div>
      <nav className={classes.menuContainer}>
        <Link to="/app/cadastrar_universidade" className={classes.navItem}>
          <CreateRounded className={classes.icon} />
          Cadastrar Universidade
        </Link>
        <Link to="/app/universidades" className={classes.navItem}>
          <SchoolRounded className={classes.icon} />
          Universidades
        </Link>
        <button onClick={handleLogout} className={classes.navItem}>
          <ExitToAppRounded className={classes.icon} />
          Sair
        </button>
      </nav>
    </div>
  );
});
