import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: '#fff',
    flexDirection: 'column',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    margin: '30px 0px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '410px',
    boxShadow: '1px 2px 2px 2px #c1c1c1',
    borderRadius: '5px',
    border: '1px solid #c1c1c1',
    padding: '40px',
  },
  h2: {
    width: '100%',
    maxWidth: '370px',
    fontSize: '25px',
    color: '#009a6b',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontWeight: 'normal',
    margin: '20px 0px 30px 0px',
  },
  table: {
    height: '250px',
    maxHeight: '250px',
  },
  hr: {
    height: '1px',
    width: '100%',
    marginTop: '20px',
    backgroundColor: '#009a6b',
  },
}));
