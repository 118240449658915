import React from 'react';
import { WarningRounded } from '@material-ui/icons';
import Header from '../../components/Header';

import styles from './styles';

export default function () {
  const classes = styles();

  const isLoggedIn = sessionStorage.getItem('@user_token');

  return (
    <div className={classes.container}>
      {isLoggedIn && <Header />}
      <div className={classes.contentContainer}>
        <div className={classes.spanContainer}>
          <WarningRounded />
          <span className={classes.span}>Universidade não cadastrada</span>
        </div>
      </div>
    </div>
  );
};
