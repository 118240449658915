import React from 'react';
import { GetAppRounded, DeleteRounded } from '@material-ui/icons';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  withStyles,
} from '@material-ui/core';

import Dialog from '../Dialog';

import api from '../../../../services/api';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#81c784',
    color: '#333',
    fontWeight: 700,
    fontSize: 14,
    border: 'none',
  },
  body: {
    fontSize: 12,
    borderBottomColor: '#9bd39e',
  },
}))(TableCell);

const columns = [
  { id: 'universidade', label: 'Universidade', minWidth: 100 },
  { id: 'planilha', label: 'Planilha', minWidth: 10 },
  { id: 'remover', label: 'Remover', minWidth: 10 },
];


const rowsPerPage = 20;

export default ({ data, openSnackbar, setUniversidades, ...props }) => {
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [remUniversidade, setRemUniverdade] = React.useState('');

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const getPlanilha = async (universidade) => {
    try {
      const res = await api.get(`/university/planilha/${universidade}`);
      const blob = new Blob([new Uint8Array(res.data.data.data)]);
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.target = '_blank';
      a.download = `${universidade}.xlsx`;
      a.click();
    } catch (e) {
      open('Ocorreu um erro ao tentar baixar a planilha');
    }
  };

  const removerUniversidade = async () => {
    try {
      await api.delete(`/university/${remUniversidade}`);
      setUniversidades(data.filter(u => u !== remUniversidade));
      setOpen(false);
      setRemUniverdade('');
    } catch (e) {
      openSnackbar('Ocorreu um erro ao tentar remover a universidade');
    }
  }

  const dialog = open && <Dialog
    title="Remover universidade"
    msg={`Tem certeza que deseja remover a universidade "${remUniversidade}" ?`}
    handleClose={() => { setOpen(false); setRemUniverdade(''); }}
    handleSubmit={() => removerUniversidade()}
  />;


  return (
    <React.Fragment>
      <TableContainer {...props}>
        <Table size="small" stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                  
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((universidade, index) => (
              <TableRow hover key={`row-${index}`}>
                <StyledTableCell align="center">{universidade}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={(e) => getPlanilha(universidade)}>
                    <GetAppRounded />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={(e) => { setRemUniverdade(universidade); setOpen(true); }}>
                    <DeleteRounded />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
            </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
      {dialog}
    </React.Fragment>
  );
};
